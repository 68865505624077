@import url('https://fonts.googleapis.com/css?family=Inter:400');

body {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f7f5f3;
  animation-duration: 0.75s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}


  .home-title-header {
    text-align: center;
    background-color:#f7f5f3;
    min-height: 40vh;
    max-height: 40vh;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    font-size: calc(4vmin);
    color:black;
    vertical-align: bottom;
  }

  .bar{
    margin: 0px;
    padding: 0px;
    min-height: 12vh;
    max-height: 15vh;
    min-width: 100vw;
    margin-left: 5vw;
    margin-right: 155vw;
    margin-top: 0.75vh;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  .bar-text{
    font-family: 'Inter', sans-serif;
    font-size: calc(1.5vw + 1.5vh);
    color: white;
    text-align: left;
    margin: 0;
    padding: 0;
    width: 80%;
    margin-left: 2%;
    margin-right: 18%;

  }

  .nav-bar-text{
    -webkit-transition: 0.25s ease-in-out;
    font-family: 'Inter', sans-serif;
    font-size: calc(10px + 3vmin);
    max-width: 20vw;
    color: white;
    padding-right: 4vw;
  }

  .nav-bar-text:hover{
    -webkit-transition: 0.25s ease-in-out;
    /* transform: rotate(10deg); */
  }

  .home-title{
      font-family: 'Inter', sans-serif;
      font-size: 1.5em; 
      color: black;
      flex-direction: row;
      display: flex;
  }

  .home-title-letter{
    vertical-align: bottom;
    text-align: center;
    flex-direction: row;
    display: flex;
    /* min-width: 3vw;
    max-width: 3vw; */
    padding: 0.25vw;
  }

  .home-body{
    min-height: 60vh;
    max-height: 60vh;
    background-color:white;
    /* overflow-x: hidden; */
    overflow: visible;
    /* background-color: black; */
    text-align: center;
  }

  .home-box{
    overflow: hidden;
    background-color: #f7f5f3;
  }

  .nav-bar{
    min-width: 100vw;
    max-width: 100vw;
    min-height: 10vh;
    max-height: 10vh;

    background-color: #f7f5f3;
    vertical-align: center;
  }
  
  .links{
    text-decoration: none;  
  }
  