@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

@keyframes flashing {
  from {
    background-color: #ccc;
  }

  to {
    background-color: #000;
  }
}

body {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  animation-duration: 0.25s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  background-color: #f7f5f3;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.photopage-blur {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  overflow: hidden;
  background-color: #ccc;

  animation-duration: 0.75s;
  animation-name: flashing;
  animation-iteration-count: infinite;
}

.photopage-photo-description-container {
  height: 100vh;
  width: 40vw;

  text-align: center;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* background-color: #f2eae1; */
  background: radial-gradient(circle, rgba(242, 234, 225, 1) 56%,  rgba(227, 217, 206, 1) 100%);

}

.photopage-photo-description-text {
  height: 80%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;


  display: flex;
  align-items: flex-end;
  flex-direction: column;
  align-items: center;

  font-size: 1.8em;
}

.photopage-photo-description-text:hover {
  cursor: default;
}

.photopage-photo-mode-gallery-text {
  padding-left: 10px; 
  padding-right: 10px; 
  border-radius: 20px;

  animation-name: fade;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-direction: alternate-reverse;
  animation-timing-function: ease;
}

.photopage-photo-contact-text {
  font-weight: 500;
  font-size: 0.4em;
}

.photopage-photo-contact-text:hover{
  opacity: 60%;
  animation-name: wiggle;
  animation-duration: .75s;
  animation-iteration-count: 1;
  animation-direction: normal;
}

@keyframes wiggle {
  0%{ transform: rotate(0deg); }
  30% { transform: rotate(2deg); }
  70% { transform: rotate(-2deg); }
  90% { transform: rotate(1deg); }

  }

.photopage-photo-go-back {
  margin-top: 2.5%;
  height: 50%;
  width: 15%;

  font-size: 2.5em;
  text-decoration: none; 
  align-self: flex-start;
}

.photopage-photo-next-image {
  width: 4vw;
  height: 100vh;
  line-height: 100vh;

  margin-top: 0;
  padding-left: 0.5vw;
  padding-right: 0.5vw;

  text-align: center;
  font-size: 3em;
  font-weight: 600;

  color: rgba(232, 188, 130, 1);
  cursor: pointer;
  background-color: "#f7f5f3";


  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.photopage-photo-next-image.animate {
  animation-duration: 0.35s;
  /* animation-duration: 1s; */

  animation-name: clicked;
  animation-iteration-count: 1;
}

@keyframes clicked {
  0%   {opacity: 1;}
  30%  {opacity: 0.5;}
  100% {opacity: 1;}
}

.photopage-photobox-fade {
  animation-duration: 0.1s;
  animation-name: fadeaway;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeaway {
  from  {
        opacity: 1;
        }
  to {
    opacity: 0;
  }
}

.photopage-img-container {
  height: 100vh;
  width: 60vw;

  display: flex;
  justify-content: center;
  pointer-events: none;
}

.photopage-img-frame-tall {
  align-self: center;

  min-height: 85vh;
  max-height: 85vh;
  min-width: 60vh;
  max-width: 70vw;
  width: auto;

  display: flex;
  justify-content: center;

  padding: 1.5vw;
  border-radius: 2px;

  /* animation-duration: 0.1s;
  animation-name: fadeIn; 
  animation-iteration-count: 1; */

  background-color: black;
  
  background: radial-gradient(circle, rgba(242, 234, 225, 1) 56%,  rgba(227, 217, 206, 1) 100%);
}

.photopage-img-frame-wide {
  align-self: center;

  height: auto;
  min-height: 35vw;
  max-height: 80vh;
  min-width: 48vw;
  max-width: 50vw;

  display: flex;
  justify-content: center;

  padding: 2vw;
  border-radius: 2px;

  /* animation-duration: 0.1s;
  animation-name: fadeIn;
  animation-iteration-count: 1; */

  /* background-color: black; */
  background: radial-gradient(circle, rgba(242, 234, 225, 1) 56%,  rgba(227, 217, 206, 1) 100%);
}

.photopage-img-tall{
  align-self: center;

  min-height: 85vh;
  max-height: 85vh;
  width: auto;

  animation-duration: 0.3s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}

.photopage-img-wide{
  align-self: center;

  height: auto;
  min-width: 50vw;
  max-width: 50vw;

  animation-duration: 0.3s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}

.photopage-loader {
  align-self: center;
}
  
.photo-box-container{
    background-color: #f7f5f3;
    object-fit: contain;
    vertical-align: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  }

.photo-box {
  align-content: center;
}

.photo-list {
    padding: 0;
    margin: 0;
    width: calc(100%);
    text-align: center;
    align-content: center;
	  list-style: none;
}

.photo-frame-tall{
  margin: 2.5%;
  padding: 2vw;

  height: fit-content;
  width: fit-content;

  background-color: black;

  border-radius: 2px;

  animation-duration: 2s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}

.photo-frame-wide{
  margin: 2.5%;
  padding: 2em;


  height: fit-content;
  width: fit-content;

  background-color: black;

  border-radius: 2px;

  animation-duration: 2s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}

/* -------------------------------------------------------------------  All the stuff for phone layout down below.  ----------------------------------------------------------------- */

.photopage-photo-display-container-desktop { 
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
}

.photopage-photo-list-container-desktop { 
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between
}


.photopage-photo-display-container-phone {
  height: 100%;
  width: 100%;
  position: absolute;
}

.photopage-top-pane-fit {
  /* display: table-cell;  */
  /* vertical-align: middle; */
  height: 75%;
  width: 100%;
  position: relative;  /* overflow: hidden; */
}

.photopage-middle-pane-fit {
  height: 5%;
  width: 100%;
}

.photopage-bottom-pane-fit {
  height: 15%;
  width: 100%;
}


.photopage-photo-next-image-container-phone {
  width: 100%;
  height: 5%;
  /* float: bottom; */
}

.photopage-photo-next-image-phone {
  width: 6.75vw;
  height: 5vh;
  line-height: 5vh;


  font-size: 1.5em;
  text-decoration: none; 

  margin: 0;
  padding: 0;
  padding-left: 28vw;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.photopage-photo-next-image-phone.animate {
  animation-duration: 0.35s;
  /* animation-duration: 1s; */

  animation-name: clicked;
  animation-iteration-count: 1;
}

.photopage-photo-description-container-phone {
  height: 100vh; 
  width: 100vw;

  text-align: center;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* background-color: #f2eae1; */
  background: radial-gradient(circle, rgba(242, 234, 225, 1) 56%,  rgba(227, 217, 206, 1) 100%);
}

.photopage-photo-description-text-phone {
  height: 30vh;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;


  display: flex;
  align-items: flex-end;
  flex-direction: column;
  align-items: center;

  font-size: 0.8em;
}

.photopage-photo-contact-text-phone {
  font-weight: 500;
  font-size: x-small;
}

.photopage-top-container-phone {
  width: 100%;
  min-height: 70vh;
  max-height: 70vh;
}

.photopage-photo-go-back-phone {
  height: 5vh;
  line-height: 5vh;
  width: 100vw;
  padding-top: 0.5vh;
  padding-left: 0.5vh;


  font-size: 1.5em;
  text-decoration: none; 
}

.photopage-img-container-phone {
  min-height: 65vh;
  max-height: 65vh;
  width: 100%;

  display: flex;
  justify-content: center;
  pointer-events: none;
}

.photopage-img-frame-tall-phone {
  align-self: center;

  min-width: 40vh;
  max-width: 40vh;
  min-height: 60vh;
  max-height: 60vh;
  /* width: auto; */

  display: flex;
  justify-content: center;

  padding: 1.5vw;
  border-radius: 2px;

  animation-duration: 0.35s;
  animation-name: fadeIn;
  animation-iteration-count: 1;

  /* background-color: black; */
  
  background: radial-gradient(circle, rgba(242, 234, 225, 1) 56%,  rgba(227, 217, 206, 1) 100%);
}

.photopage-img-frame-wide-phone {
  align-self: center;

  /* height: auto; */
  min-width: 90vw;
  max-width: 90vw;
  min-height: 60vw;
  max-height: 60vw;

  display: flex;
  justify-content: center;

  padding: 2vw;
  border-radius: 2px;

  animation-duration: 0.35s;
  animation-name: fadeIn;
  animation-iteration-count: 1;

  /* background-color: black; */
  background: radial-gradient(circle, rgba(242, 234, 225, 1) 56%,  rgba(227, 217, 206, 1) 100%);
}

.photopage-img-tall-phone {
  align-self: center;

  min-width: 40vh;
  max-width: 40vh;
  min-height: 60vh;
  max-height: 60vh;
  width: auto;

  animation-duration: 0.35s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}

.photopage-img-wide-phone {
  align-self: center;

  /* height: auto; */
  min-width: 90vw;
  max-width: 90vw;
  min-height: 60vw;
  max-height: 60vw;

  animation-duration: 0.35s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
}


@keyframes fade {
  from{
    background-color: #CCCCCC;
  }
  to{
    opacity: 90%;
    background-color: #666666;
  }
}