.grid-container-desktop {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 5% 0%;
    overflow: scroll;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    justify-items: center;
  }

.grid-item-desktop {
    height: 30vh;
    width: 30vh;    
    overflow: hidden;
    place-items: center;
    display: block;  
}