@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');

body {
  background-color:#f7f5f3;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  animation-duration: 0.75s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

.collections-title-header {
    text-align: left;
    background-color: 	#f7f5f3;
    min-height: 25vh;
    max-height: 35vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    font-size: calc(4vmin);
    color:black;
    vertical-align: bottom;
    padding: 0;
    margin: 0;
  }


.collections-title{
    vertical-align: bottom;
    font-family: 'Inter', sans-serif;
    font-size: calc(2vw + 2vh);
    color: black;
    display: flex;
    max-width: 90vw;
    justify-content: flex-end;
    flex-direction: column;
    vertical-align: bottom;
    padding: 0;
    margin: 0;
}

.collections-title-letter{
  vertical-align: bottom;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /* min-width: 3vw;
  max-width: 3vw; */
  padding: 0;
  margin: 0;
  margin-left: 5vw;
  margin-right: 5vw;
}