.photoframe-container-desktop-tall {
    align-self: center;

    min-height: 94vh;
    max-height: 94vh;
    width: auto;

    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: 2vw;
    margin-right: 2vw;
}

.photoframe-container-desktop-wide {
    align-self: center;

    height: auto;
    max-width: 55vw;
    min-width: 55vw;

    margin-top: 10vh;
    margin-bottom: 10vh;
    margin-left: 0;
    margin-right: 0;
}

.loading-placeholder-desktop-tall {
    min-height: 94vh;
    max-height: 94vh;
    width: 60vh;

    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: 2vw;
    margin-right: 2vw;

    -webkit-filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -o-filter: blur(0.5px);
    -ms-filter: blur(0.5px);
    filter: blur(0.5px);

    animation-name: flashing;
    animation-duration: 0.85s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
}

.loading-placeholder-desktop-wide {
    height: 36vw;
    width: 55vw;

    margin-top: 10vh;
    margin-bottom: 10vh;
    margin-left: 2vw;
    margin-right: 2vw;

    -webkit-filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -o-filter: blur(0.5px);
    -ms-filter: blur(0.5px);
    filter: blur(0.5px);

    animation-name: flashing;
    animation-duration: 0.85s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
}

.photoframe-container-mobile-tall {
    align-self: center;
    position: absolute;
    object-fit: cover;

    min-height: 60%;
    max-height: 90%;
    height: auto;
    width: auto;
    max-width: 90%;    /* min-height: 50vh; */
    /* min-height: 50%;
    max-height: 55%; */
    /* min-width: 50%; */
    /* max-width: 85%; */
    /* max-height: 60%; */
    /* height: auto; */
    /* min-width: 75%;
    max-width: 90%; */

    margin-top: 2.5%;
    margin-bottom: 2.5%;

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.photoframe-container-mobile-wide {
    align-self: center;

    min-height: 20%;
    max-height: 40%;
    min-width: 85%;
    max-width: 90%;
    width: auto;

    margin-top: 35%;
    margin-bottom: 10%;
    margin-left: 5%;
    margin-right: 5%;
}


.loading-placeholder-mobile-tall {
    height: 60vh;
    width: 90vw;

    /* margin-top: 3vh; */
    margin-bottom: 3vh;
    margin-left: 5vw;
    margin-right: 5vw;

    -webkit-filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -o-filter: blur(0.5px);
    -ms-filter: blur(0.5px);
    filter: blur(0.5px);

    animation-name: flashing;
    animation-duration: 0.85s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
}

.loading-placeholder-mobile-wide {
    height: 65vw;
    width: 90vw;

    margin-top: 10vh;
    margin-bottom: 10vh;
    margin-left: 5vw;
    margin-right: 5vw;

    -webkit-filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -o-filter: blur(0.5px);
    -ms-filter: blur(0.5px);
    filter: blur(0.5px);

    animation-name: flashing;
    animation-duration: 0.85s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
}



/* Grid things below here. */

.photoframe-container-grid-wide {
    width: auto;
    height: auto;
    max-height: 98%;
    max-width: 100%;
    margin-top: 15%;
}

.photoframe-container-grid-tall {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 65%;

    margin-left: 17.5%;
    margin-right: 17.5%;
}

.loading-placeholder-grid{
    width: auto;
    height : auto;
    max-height: 100%;
    max-width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
    margin-right: 1%;

    -webkit-filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -o-filter: blur(0.5px);
    -ms-filter: blur(0.5px);
    filter: blur(0.5px);

    animation-name: flashing;
    animation-duration: 0.85s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
}

.shaded{
   opacity: 70%;

   -webkit-transition: all 0.08s linear;
    -moz-transition: all 0.08s linear;
    -o-transition: all 0.08s linear;
    transition: all 0.08s linear;  
}


@keyframes flashing {
    from{
      background-color: #CCCCCC;
    }
    to{
      background-color: #666666;
    }
  }